body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#menu- .MuiPaper-root{
  box-shadow: 0px 0px 23px -5px #777777;
}

.react-datepicker {
  font-size: 0.6em !important;
}
.react-datepicker__header {
  padding-top: 0.8em !important;
}
.react-datepicker__month {
  margin: 0.2em 0.5em !important;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
}
.react-datepicker__current-month {
  font-size: 0.5em !important;
}
.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
  left: 1em !important;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
 }
.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}